import React from 'react';

const Solutions = () => {
  return (
    <section id="solutions" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6 gradient-text">Solutions</h2>
        <p className="text-center text-gray-700 mb-8">
          We have different solutions tailored to the needs of various company sizes.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Solution for Individuals */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center">
            <img
              src="https://popl.co/cdn/shop/files/Popl_for_Freelancers_360x360.png?v=1700576604"
              alt="Individuals"
              className="mb-4 w-full h-32 object-cover"
            />
            <h3 className="text-xl font-bold mb-4">Individuals</h3>
            <p className="text-gray-600 mb-4 flex-grow">
              Perfect for personal use to share your contact information and social media profiles easily.
            </p>
          </div>
          {/* Solution for Professionals & Entrepreneurs */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center">
            <img
              src="https://popl.co/cdn/shop/files/Popl_for_Executives_360x360.png?v=1700576606"
              alt="Professionals & Entrepreneurs"
              className="mb-4 w-full h-32 object-cover"
            />
            <h3 className="text-xl font-bold mb-4">Professionals & Entrepreneurs</h3>
            <p className="text-gray-600 mb-4 flex-grow">
              Ideal for professionals and entrepreneurs looking to expand their network and manage contacts efficiently.
            </p>
          </div>
          {/* Solution for SMBs */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center">
            <img
              src="https://popl.co/cdn/shop/files/Popl_For_Business_Networking_360x360.png?v=1700576604"
              alt="SMBs"
              className="mb-4 w-full h-32 object-cover"
            />
            <h3 className="text-xl font-bold mb-4">SMBs</h3>
            <p className="text-gray-600 mb-4 flex-grow">
              Designed for small and medium-sized businesses to streamline contact sharing and improve team efficiency.
            </p>
          </div>
          {/* Solution for Enterprise */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center">
            <img
              src="https://popl.co/cdn/shop/files/Popl_for_C-level_360x360.png?v=1700576605"
              alt="Enterprise"
              className="mb-4 w-full h-32 object-cover"
            />
            <h3 className="text-xl font-bold mb-4">Enterprise</h3>
            <p className="text-gray-600 mb-4 flex-grow">
              Tailored for large and complex organizations to manage extensive networks and ensure seamless contact integration.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
