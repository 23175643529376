import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { FaTimes } from 'react-icons/fa';
import ytPreviewImage from '../assets/maxresdefault.png'
import ytButton from '../assets/yt-play-button.svg'

const SwahilicardAction = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="flex flex-col items-center bg-gray-100 py-10">
      <h2 className="text-2xl font-bold mb-6 gradient-text">Watch Swahilicard in Action</h2>
      <div className="relative w-full max-w-4xl">
        <img 
          src={ytPreviewImage} 
          alt="Popl in Action" 
          className="w-full h-auto cursor-pointer" 
          onClick={openModal}
        />
        <div 
          className={`absolute inset-0 flex items-center justify-center ${isOpen ? 'hidden' : ''}`} 
          onClick={openModal}
        >
          <img 
            src={ytButton} 
            alt="Play Button" 
            className="w-16 h-16 md:w-24 md:h-24 cursor-pointer"
          />
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white p-4 rounded-lg">
            <button 
              className="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-900"
              onClick={closeModal}
            >
              <FaTimes className="w-6 h-6" />
            </button>
            <YouTube videoId="2cLo6SJ3hY0" opts={opts} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SwahilicardAction;
