// import React from 'react';
// import Header from './components/Header';
// import HeroSection from './components/HeroSection';
// import TrustedBySection from './components/TrustedBySection';
// import FeaturesSection from './components/FeaturesSection';
// import HowItWorks from './components/HowItWorks';
// import BuyProducts from './components/BuyProducts';
// import Solutions from './components/Solutions';
// import TeamsAndBusinessSolutions from './components/TeamsAndBusinessSolutions';
// import SwahilicardAction from './components/SwahiliCardAction';
// import FaQ from './components/FaQ';
// import Sustainability from './components/Sustainability';
// import Footer from './components/Footer';


// function App() {
//   return (
//     <div className="App">
//       <Header />
//       <HeroSection />
//       <TrustedBySection />
//       <FeaturesSection />
//       <HowItWorks/>
//       <SwahilicardAction />
//       <BuyProducts/>
//       <Solutions/>
//       <TeamsAndBusinessSolutions/>
//       <FaQ/>
//       <Sustainability/>
//       <Footer/>
//     </div>
//   );
// }

// export default App;


import React, { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import TrustedBySection from './components/TrustedBySection';
import FeaturesSection from './components/FeaturesSection';
import HowItWorks from './components/HowItWorks';
import BuyProducts from './components/BuyProducts';
import Solutions from './components/Solutions';
import TeamsAndBusinessSolutions from './components/TeamsAndBusinessSolutions';
import SwahilicardAction from './components/SwahiliCardAction';
import FaQ from './components/FaQ';
import Sustainability from './components/Sustainability';
import Footer from './components/Footer';
import { initializeGA, logPageView } from './analytics';

function App() {
  const location = useLocation();

  useEffect(() => {
    const trackingID = 'G-GZR6RV2ST1'; // Replace with your tracking ID
    initializeGA(trackingID);
    logPageView();
  }, [location]);

  return (
    <div className="App">
      <Header />
      <HeroSection />
      <TrustedBySection />
      <FeaturesSection />
       <HowItWorks/>
      <SwahilicardAction />
       <BuyProducts/>
       <Solutions/>
       <TeamsAndBusinessSolutions/>
       <FaQ/>
      <Sustainability/>
      <Footer />
    </div>
  );
}

export default App;
