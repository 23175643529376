import ReactGA from 'react-ga';

const initializeGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

const logPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export { initializeGA, logPageView };