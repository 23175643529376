import React from 'react';
import heroImage from '../assets/hero_image1.png';
import appStoreIcon from '../assets/appstore_icon.png';
import playStoreIcon from '../assets/playstore_icon.png';
import qrCode from '../assets/qr_code.png';

const HeroSection = () => (
  <section id="home" className="custom-bg min-h-screen flex items-center justify-center">
    <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between py-4 px-4 md:px-8 lg:px-16">
      <div className="lg:w-1/2 text-center lg:text-left mb-12 lg:mb-0">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 gradient-text relative">The #1 Revolutionary</h1>
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 gradient-text">Digital Business Card</h2>
        <p className="text-lg md:text-xl mb-8 text-gray-600">Gain more leads at events, conferences, and other occasions.</p>
        <div className="flex flex-col items-center lg:items-start space-y-4 w-full max-w-md mx-auto lg:mx-0">
          <div className="text-lg font-bold">DOWNLOAD APP</div>
          <div className="flex flex-col sm:flex-row sm:space-x-4 w-full">
            <a href="#" className="bg-white text-black py-2 px-4 rounded-lg flex items-center space-x-2 border border-gray-300 hover:bg-gray-100 mb-4 sm:mb-0 w-full sm:w-1/2">
              <img src={appStoreIcon} alt="App Store" className="h-6" />
              <span>App Store</span>
            </a>
            <a href="#" className="bg-white text-black py-2 px-4 rounded-lg flex items-center space-x-2 border border-gray-300 hover:bg-gray-100 w-full sm:w-1/2">
              <img src={playStoreIcon} alt="Google Play" className="h-6" />
              <span>Google Play</span>
            </a>
          </div>
          <div className="bg-white py-2 px-4 sm:py-1 sm:px-2 rounded-lg border border-gray-300 flex items-center space-x-2 sm:space-x-4 hover:bg-gray-100 w-full">
            <img src={qrCode} alt="QR Code" className="h-12 sm:h-16" />
            <div className="text-base sm:text-lg text-gray-700">
              <span className="font-bold gradient-text">Scan the QR</span> with your phone
              <br />
              to download the <span className="font-bold gradient-text">Swahilicard app</span>.
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 text-center flex justify-center">
        <img src={heroImage} alt="Digital Business Card Example" className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl image-3d" id="hero-image" />
      </div>
    </div>
  </section>
);

export default HeroSection;
