import React from 'react';

const TrustedBySection = () => (
  <section className="bg-gray-50 py-12">
    <div className="container mx-auto text-center px-4 md:px-0">
      <h2 className="text-2xl font-bold mb-6 gradient-text">Professionals and teams trust Swahilicard</h2>
      <div className="flex flex-wrap justify-center space-x-4">
        <img src="https://cdn.shopify.com/s/files/1/0561/0422/2871/files/tmobile_logo.svg?v=1710766565" alt="Logo 2" className="h-12" />
        <img src="https://cdn.shopify.com/s/files/1/0518/6093/0731/files/logo_1.svg?v=1701775632" alt="Logo 3" className="h-12" />
        <img src="https://cdn.shopify.com/s/files/1/0561/0422/2871/files/generalilogo.svg?v=1710766956" alt="Logo 4" className="h-12" />
        <img src="https://cdn.shopify.com/s/files/1/0561/0422/2871/files/porsche5.svg?v=1710765587" alt="Logo 1" className="h-12" />
        <img src="https://cdn.shopify.com/s/files/1/0518/6093/0731/files/logo_5.svg?v=1701776649" alt="Logo 6" className="h-12" />
      </div>
    </div>
  </section>
);

export default TrustedBySection;
