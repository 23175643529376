import React, { useState } from 'react';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="sticky top-0 bg-white z-50">
      <div className="container mx-auto flex justify-between items-center py-4 px-4 md:px-8 lg:px-16">
        <div className="text-2xl font-bold text-green-600">Swahilicard</div>
        <nav className="hidden md:flex space-x-6">
          <a href="#home" className="text-gray-700 hover:text-green-600">Home</a>
          <a href="#features" className="text-gray-700 hover:text-green-600">Features</a>
          <a href="#products" className="text-gray-700 hover:text-green-600">Buy Products</a>
          <a href="#solutions" className="text-gray-700 hover:text-green-600">Solutions</a>
          <a href="#businessandteams" className="text-gray-700 hover:text-green-600">For Businesses & Teams</a>
          <a href="#faq" className="text-gray-700 hover:text-green-600">FAQ</a>
          <a href="#sustainability" className="text-gray-700 hover:text-green-600">Sustainability</a>
        </nav>
        <div className="hidden md:flex items-center space-x-2">
          <button className="text-gray-700 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
            </svg>
          </button>
          <button id="order-now" className="gradient-button text-white px-4 py-2 rounded-md hover:bg-blue-600">Order Now</button>
        </div>
        <button className="md:hidden text-gray-700 focus:outline-none" onClick={() => setMenuOpen(!menuOpen)}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      {menuOpen && (
        <nav className="md:hidden bg-white border-t border-gray-200 shadow-lg">
          <a href="#home" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Home</a>
          <a href="#features" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Features</a>
          <a href="#products" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Buy Products</a>
          <a href="#solutions" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Solutions</a>
          <a href="#businessandteams" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">For Businesses & Teams</a>
          <a href="#faq" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">FAQ</a>
          <a href="#sustainability" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Sustainability</a>
          <div className="flex justify-between px-4 py-2 border-t border-gray-200">
            <button className="text-gray-700 focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
              </svg>
            </button>
            <button id="order-now" className="gradient-button text-white px-4 py-2 rounded-md hover:bg-blue-600">Order Now</button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;




