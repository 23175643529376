// import React from 'react';

// const Footer = () => {
//   return (
//     <footer className="bg-gradient-to-r from-green-500 via-green-700 to-black text-white py-10">
//       <div className="container mx-auto px-4 flex flex-wrap justify-between">
//         {/* Column 1: Logo and Description */}
//         <div className="w-full md:w-1/3 mb-6 md:mb-0">
//           <h2 className="text-2xl font-bold mb-4">Swopi</h2>
//           <p>
//             Swopi allows you to instantly share social networks, music, payment
//             accounts, and contact information with the click of a phone.
//           </p>
//         </div>
//         {/* Column 2: Links */}
//         <div className="w-full md:w-1/3 mb-6 md:mb-0">
//           <h2 className="text-xl font-bold mb-4">Links</h2>
//           <ul>
//             <li>
//               <a href="#" className="hover:underline">
//                 Home
//               </a>
//             </li>
//             <li>
//               <a href="#" className="hover:underline">
//                 Shop
//               </a>
//             </li>
//             <li>
//               <a href="#" className="hover:underline">
//                 About Us
//               </a>
//             </li>
//             <li>
//               <a href="#" className="hover:underline">
//                 Contact
//               </a>
//             </li>
//             <li>
//               <a href="#" className="hover:underline">
//                 FAQ
//               </a>
//             </li>
//           </ul>
//         </div>
//         {/* Column 3: Contact Information */}
//         <div className="w-full md:w-1/3">
//           <h2 className="text-xl font-bold mb-4">Contact Us</h2>
//           <p>Email: support@swopi.co</p>
//           <p>Phone: +1 (555) 123-4567</p>
//           <div className="flex space-x-4 mt-4">
//             <a href="#" className="text-white hover:text-gray-300">
//               <img
//                 src="https://image.flaticon.com/icons/svg/1384/1384053.svg"
//                 alt="Facebook"
//                 className="h-6"
//               />
//             </a>
//             <a href="#" className="text-white hover:text-gray-300">
//               <img
//                 src="https://image.flaticon.com/icons/svg/1384/1384055.svg"
//                 alt="Twitter"
//                 className="h-6"
//               />
//             </a>
//             <a href="#" className="text-white hover:text-gray-300">
//               <img
//                 src="https://image.flaticon.com/icons/svg/1384/1384058.svg"
//                 alt="Instagram"
//                 className="h-6"
//               />
//             </a>
//           </div>
//         </div>
//       </div>
//       <div className="mt-8 border-t border-gray-700 pt-4 text-center">
//         <p>© 2024 Swahilicard. All rights reserved.</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;




// src/components/Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-green-600 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <img
              src="https://play-lh.googleusercontent.com/EHpZib7gzVScZtbxNGjNigqnighswZ_noF4QyFtStagG1FaKBpyTx5Dh_8qCmKuF-ndP=w480-h960-rw"
              alt="Swahilicard Logo"
              className="h-12 mb-4"
            />
            <p>© 2024 Swahilicard. All rights reserved.</p>
          </div>
          <div>
            <h5 className="font-bold mb-4">Company</h5>
            <ul>
              <li>
                <a href="#" className="hover:underline">
                  NFC Smart Business Card
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  SwahiliCard for Business
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  E-Book
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-4">Support</h5>
            <ul>
              <li>
                <a href="#" className="hover:underline">
                  Contact us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  FAQ - Help
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  🔒 GDPR
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Impressum
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Intellectual Property
                </a>
              </li>
           
            </ul>
          </div>
        </div>
        <div className="mt-8 flex justify-between items-center">
          <div className="flex space-x-4">
            <a href="#" className="hover:underline">
              Facebook
            </a>
            <a href="#" className="hover:underline">
              YouTube
            </a>
            <a href="#" className="hover:underline">
              Instagram
            </a>
            <a href="`#`" className="hover:underline">
              TikTok
            </a>
            <a href="#" className="hover:underline">
              LinkedIn
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="https://us.tapni.com/cdn/shop/files/tapni-app-download_400x.webp?v=1690325110"
                alt="DownloadSwahilicard App"
                className="h-12"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
