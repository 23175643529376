// import React from 'react';
// import step1Image from '../assets/step1.png';
// import step2Image from '../assets/step20.png';
// import step3Image from '../assets/step3.png';

// const Features = () => {
//   return (
//     <section id="features" className="custom-bg py-12">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         {/* First Feature Block */}
//         <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
//           <div className="order-1 w-full lg:w-1/2 lg:pr-8">
//             <img src={step1Image} alt="Capture Leads" className="mx-auto" />
//           </div>
//           <div className="order-2 w-full lg:w-1/2">
//             <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Step 1</h2>
//             <p className="mt-2 text-3xl gradient-text leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
//               Share fully up to <br /> date contact info
//             </p>
//             <div className="mt-4 h-1 bg-gradient-to-r from-blue-400 via-green-500 to-purple-600 w-24"></div>
//             <p className="mt-6 text-base text-gray-500">
//               Create stunning digital business cards that share fully up-to-date business info with anyone.
//             </p>
//             <p className="mt-5 text-base text-gray-500">
//               With a simple scan of a Swahilicard QR code, you make it easy for others to instantly save you as a contact right to their phone.
//             </p>
//             <div className="mt-8">
//               <a
//                 href="#"
//                 className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white py-2 px-6 rounded-full hover:from-green-500 hover:via-green-600 hover:to-green-700 inline-block text-center"
//               >
//                 Get Started
//               </a>
//             </div>
//           </div>
//         </div>
//         {/* Second Feature Block */}
//         <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mt-16 lg:mt-8">
//           <div className="order-1 w-full lg:w-1/2">
//             <img src={step2Image} alt="Another Feature" className="mx-auto" />
//           </div>
//           <div className="order-2 w-full lg:w-1/2 lg:pl-8 mt-10 lg:mt-0">
//             <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Step 2</h2>
//             <p className="mt-2 text-3xl gradient-text leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
//               Capture leads <br /> anywhere
//             </p>
//             <div className="mt-4 h-1 bg-gradient-to-r from-blue-400 via-green-500 to-purple-600 w-24"></div>
//             <p className="mt-6 text-base text-gray-500">
//               Instantly transform any event badge, paper business card, or digital business card QR code into a qualified lead using the AI-powered scanner in the Swahilicard mobile app.
//             </p>
//             <p className="mt-5 text-base text-gray-500">
//               Say goodbye to spending thousands of dollars on different clunky lead scanning softwares at each event.
//             </p>
//             <div className="mt-8">
//               <a
//                 href="#"
//                 className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white py-2 px-6 rounded-full hover:from-green-500 hover:via-green-600 hover:to-green-700 inline-block text-center"
//               >
//                 Learn More
//               </a>
//             </div>
//           </div>
//         </div>
//         {/* Third Feature Block */}
//         <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mt-16 lg:mt-8">
//           <div className="order-1 w-full lg:w-1/2 lg:pr-8">
//             <img src={step3Image} alt="Another Feature" className="mx-auto" />
//           </div>
//           <div className="order-2 w-full lg:w-1/2">
//             <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Step 3</h2>
//             <p className="mt-2 text-3xl gradient-text leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
//               Sync 100% of<br /> your leads to CRM
//             </p>
//             <div className="mt-4 h-1 bg-gradient-to-r from-blue-400 via-green-500 to-purple-600 w-24"></div>
//             <p className="mt-6 text-base text-gray-500">
//               Manual data entry causes teams to lose up to 60% of their potential leads & revenue. With Swahilicard’s robust CRM integrations, you can ensure that 100% of leads are enriched and auto-synced.
//             </p>
//             <p className="mt-5 text-base text-gray-500">
//               Eliminate hours of tedious data entry and maximize your team's productivity.
//             </p>
//             <div className="mt-8">
//               <a
//                 href="#"
//                 className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white py-2 px-6 rounded-full hover:from-green-500 hover:via-green-600 hover:to-green-700 inline-block text-center"
//               >
//                 Discover More
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Features;


import React from 'react';
import step1Image from '../assets/step1.png';
import step2Image from '../assets/step20.png';
import step3Image from '../assets/step3.png';

const Features = () => {
  return (
    <section id="features" className="custom-bg py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* First Feature Block */}
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div className="order-1 w-full lg:w-1/2 lg:pr-8">
            <img src={step1Image} alt="Capture Leads" className="mx-auto" />
          </div>
          <div className="order-2 w-full lg:w-1/2">
            <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Step 1</h2>
            <p className="mt-2 text-3xl gradient-text leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Share fully up to <br /> date contact info
            </p>
            <div className="mt-4 h-1 bg-gradient-to-r from-blue-400 via-green-500 to-purple-600 w-24"></div>
            <p className="mt-6 text-base text-gray-500">
              Create stunning digital business cards that share fully up-to-date business info with anyone.
            </p>
            <p className="mt-5 text-base text-gray-500">
              With a simple scan of a Swahilicard QR code, you make it easy for others to instantly save you as a contact right to their phone.
            </p>
            <div className="mt-8">
              <a
                href="#"
                className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white py-2 px-6 rounded-full hover:from-green-500 hover:via-green-600 hover:to-green-700 inline-block text-center"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
        {/* Second Feature Block */}
        <div className="flex flex-col lg:flex-row-reverse lg:items-center lg:justify-between mt-16 lg:mt-8">
          <div className="order-1 w-full lg:w-1/2">
            <img src={step2Image} alt="Another Feature" className="mx-auto" />
          </div>
          <div className="order-2 w-full lg:w-1/2 lg:pl-8 mt-10 lg:mt-0">
            <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Step 2</h2>
            <p className="mt-2 text-3xl gradient-text leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Capture leads <br /> anywhere
            </p>
            <div className="mt-4 h-1 bg-gradient-to-r from-blue-400 via-green-500 to-purple-600 w-24"></div>
            <p className="mt-6 text-base text-gray-500">
              Instantly transform any event badge, paper business card, or digital business card QR code into a qualified lead using the AI-powered scanner in the Swahilicard mobile app.
            </p>
            <p className="mt-5 text-base text-gray-500">
              Say goodbye to spending thousands of dollars on different clunky lead scanning softwares at each event.
            </p>
            <div className="mt-8">
              <a
                href="#"
                className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white py-2 px-6 rounded-full hover:from-green-500 hover:via-green-600 hover:to-green-700 inline-block text-center"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
        {/* Third Feature Block */}
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mt-16 lg:mt-8">
          <div className="order-1 w-full lg:w-1/2 lg:pr-8">
            <img src={step3Image} alt="Another Feature" className="mx-auto" />
          </div>
          <div className="order-2 w-full lg:w-1/2">
            <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Step 3</h2>
            <p className="mt-2 text-3xl gradient-text leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Sync 100% of<br /> your leads to CRM
            </p>
            <div className="mt-4 h-1 bg-gradient-to-r from-blue-400 via-green-500 to-purple-600 w-24"></div>
            <p className="mt-6 text-base text-gray-500">
              Manual data entry causes teams to lose up to 60% of their potential leads & revenue. With Swahilicard’s robust CRM integrations, you can ensure that 100% of leads are enriched and auto-synced.
            </p>
            <p className="mt-5 text-base text-gray-500">
              Eliminate hours of tedious data entry and maximize your team's productivity.
            </p>
            <div className="mt-8">
              <a
                href="#"
                className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white py-2 px-6 rounded-full hover:from-green-500 hover:via-green-600 hover:to-green-700 inline-block text-center"
              >
                Discover More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;

