import React from 'react';

const TeamsAndBusinessSolutions = () => {
  return (
    <section id="businessandteams" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6 gradient-text">Businesses and Teams</h2>
        <p className="text-center text-gray-700 mb-8">
          Discover how our solutions can empower your teams and streamline operations for SMBs and Enterprises.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* SMB Solutions */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">For Small and Medium Businesses</h3>
            <p className="text-gray-700 mb-4">
              Our tools are designed to help SMBs increase efficiency, enhance collaboration, and drive growth.
              From digital business cards to team management features, we provide everything you need to stay ahead.
            </p>
            <ul className="mb-4 list-none">
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-100 mr-2"></span> Streamlined contact sharing
              </li>
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Improved team collaboration
              </li>
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Easy integration with existing systems
              </li>
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Scalable solutions that grow with your business
              </li>
            </ul>
            <div className="flex items-center justify-center">
              <button className="gradient-button text-white px-4 py-2 rounded-md hover:bg-green-600 w-full">
                Learn More
              </button>
            </div>
          </div>
          {/* Enterprise Solutions */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">For Enterprises</h3>
            <p className="text-gray-700 mb-4">
              Our enterprise solutions offer robust features to enhance productivity and streamline operations at scale.
              With advanced security and comprehensive analytics, your teams can work smarter and more efficiently.
            </p>
            <ul className="mb-4 list-none">
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Advanced security features
              </li>
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Comprehensive analytics and reporting
              </li>
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Customizable to fit enterprise needs
              </li>
              <li className="flex items-center text-gray-700 mb-2">
                <span className="inline-block w-2 h-2 bg-gradient-to-r from-green-500 to-black-700 mr-2"></span> Seamless integration with enterprise systems
              </li>
            </ul>
            <div className="flex items-center justify-center">
              <button className="gradient-button text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamsAndBusinessSolutions;
