import React from 'react';
import getApp from "../assets/getapp.png";
import shareCard from "../assets/step2.png";
import getAccessory from "../assets/getAccesory.png"

const HowItWorks = () => {
  return (
    <section className="py-12 custom-bg">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4 gradient-text">How Swahilicard Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-bold mb-4">1. Download Swahilicard App</h3>
            <p className="text-gray-600">
            Download our free App(Playstore/Appstore) to easiliy manage your content.
            </p>
            <img
              src={getApp}
              alt="QR Code"
              className="mx-auto mb-4"
            />
          </div>
          <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-bold mb-4">2. Share your business card</h3>
            <p className="text-gray-600">
            Share your profile using your Swahilicard Accessory or in-app QR code.
            </p>
            <img
              src={shareCard}
              alt="iPhone Widgets"
              className="mx-auto mb-4"
            />
          </div>
          <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-bold mb-4">3. Get your Swahilicard Accessory</h3>
            <p className="text-gray-600">
            Activate your Accessory via Swahilicard App and you're all set! 
            </p>
            <img
              src={getAccessory}
              alt="Text Link"
              className="mx-auto mb-4"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
