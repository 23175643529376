// import React, { useState } from 'react';
// import axios from 'axios';

// const BuyProducts = () => {
//   const [formData, setFormData] = useState({
//     email: '',
//     firstName: '',
//     lastName: '',
//     phone: '',
//     productType: '',
//     quantity: '',
//     companyName: '',
//     address: '',
//   });

//   const [errors, setErrors] = useState({});
//   const [formStatus, setFormStatus] = useState('');

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const validateName = (name) => {
//     const re = /^[a-zA-Z]+$/;
//     return re.test(String(name));
//   };

//   const validatePhone = (phone) => {
//     const re = /^\+?[1-9]\d{1,14}$/;
//     return re.test(String(phone));
//   };

//   const validateForm = () => {
//     let formErrors = {};

//     if (!validateEmail(formData.email)) {
//       formErrors.email = 'Invalid email format';
//     }
//     if (!validateName(formData.firstName)) {
//       formErrors.firstName = 'First name must contain only letters';
//     }
//     if (!validateName(formData.lastName)) {
//       formErrors.lastName = 'Last name must contain only letters';
//     }
//     if (!validatePhone(formData.phone)) {
//       formErrors.phone = 'Invalid phone number';
//     }
//     if (!formData.productType) {
//       formErrors.productType = 'Please select a product type';
//     }
//     if (!formData.quantity || isNaN(formData.quantity) || formData.quantity <= 0) {
//       formErrors.quantity = 'Quantity must be a positive number';
//     }
//     if (!formData.companyName) {
//       formErrors.companyName = 'Company name is required';
//     }
//     if (!formData.address) {
//       formErrors.address = 'Address is required';
//     }

//     setErrors(formErrors);

//     return Object.keys(formErrors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validateForm()) {
//       try {
//         const response = await axios.post('http://localhost:5000/api/send-email', formData);
//         setFormStatus('Order submitted successfully!');
//       } catch (error) {
//         setFormStatus('Error submitting order.');
//       }
//     }
//   };

//   return (
//     <section id="products" className="py-12">
//       <div className="container mx-auto px-4">
//         <h2 className="text-3xl font-bold text-center mb-6 gradient-text">Buy Products</h2>
//         <p className="text-center text-gray-700 mb-8">
//           We have accessories that seamlessly link to your digital business card for easy access.
//         </p>

//         <div className="max-w-4xl mx-auto p-8 rounded-lg shadow-lg">
//           <h3 className="text-2xl font-bold mb-4"><span className='gradient-text'>Order Now</span> to get started with Swahilicard</h3>
//           <form onSubmit={handleSubmit}>
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//               <div className="mb-4">
//                 <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Work Email*</label>
//                 <input
//                   type="text"
//                   id="email"
//                   name="email"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                 />
//                 {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="first-name" className="block text-gray-700 font-bold mb-2">First Name*</label>
//                 <input
//                   type="text"
//                   id="first-name"
//                   name="firstName"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.firstName}
//                   onChange={handleInputChange}
//                 />
//                 {errors.firstName && <p className="text-red-500 text-xs mt-2">{errors.firstName}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="last-name" className="block text-gray-700 font-bold mb-2">Last Name*</label>
//                 <input
//                   type="text"
//                   id="last-name"
//                   name="lastName"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.lastName}
//                   onChange={handleInputChange}
//                 />
//                 {errors.lastName && <p className="text-red-500 text-xs mt-2">{errors.lastName}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">Phone Number*</label>
//                 <input
//                   type="text"
//                   id="phone"
//                   name="phone"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.phone}
//                   onChange={handleInputChange}
//                 />
//                 {errors.phone && <p className="text-red-500 text-xs mt-2">{errors.phone}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="product-type" className="block text-gray-700 font-bold mb-2">Product Type*</label>
//                 <select
//                   id="product-type"
//                   name="productType"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.productType}
//                   onChange={handleInputChange}
//                 >
//                   <option value="">Choose</option>
//                   <option value="cards">Cards</option>
//                   <option value="bands">Bands</option>
//                 </select>
//                 {errors.productType && <p className="text-red-500 text-xs mt-2">{errors.productType}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="quantity" className="block text-gray-700 font-bold mb-2">Quantity*</label>
//                 <input
//                   type="number"
//                   id="quantity"
//                   name="quantity"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.quantity}
//                   onChange={handleInputChange}
//                 />
//                 {errors.quantity && <p className="text-red-500 text-xs mt-2">{errors.quantity}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="company-name" className="block text-gray-700 font-bold mb-2">Company Name*</label>
//                 <input
//                   type="text"
//                   id="company-name"
//                   name="companyName"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.companyName}
//                   onChange={handleInputChange}
//                 />
//                 {errors.companyName && <p className="text-red-500 text-xs mt-2">{errors.companyName}</p>}
//               </div>
//               <div className="mb-4">
//                 <label htmlFor="address" className="block text-gray-700 font-bold mb-2">Address*</label>
//                 <input
//                   type="text"
//                   id="address"
//                   name="address"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={formData.address}
//                   onChange={handleInputChange}
//                 />
//                 {errors.address && <p className="text-red-500 text-xs mt-2">{errors.address}</p>}
//               </div>
//             </div>
//             <div className="flex items-center justify-between mt-4">
//               <button
//                 type="submit"
//                 className="gradient-button text-white px-4 py-2 rounded-md hover:bg-blue-600"
//               >
//                 Submit Order
//               </button>
//             </div>
//             {formStatus && <p className="text-center mt-4 text-green-500">{formStatus}</p>}
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default BuyProducts;



import React, { useState } from 'react';
import axios from 'axios';

const BuyProducts = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    productType: '',
    quantity: '',
    companyName: '',
    address: '',
  });

  const [errors, setErrors] = useState({});
  const [formStatus, setFormStatus] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateName = (name) => {
    const re = /^[a-zA-Z]+$/;
    return re.test(String(name));
  };

  const validatePhone = (phone) => {
    const re = /^\+?[1-9]\d{1,14}$/;
    return re.test(String(phone));
  };

  const validateForm = () => {
    let formErrors = {};

    if (!validateEmail(formData.email)) {
      formErrors.email = 'Invalid email format';
    }
    if (!validateName(formData.firstName)) {
      formErrors.firstName = 'First name must contain only letters';
    }
    if (!validateName(formData.lastName)) {
      formErrors.lastName = 'Last name must contain only letters';
    }
    if (!validatePhone(formData.phone)) {
      formErrors.phone = 'Invalid phone number';
    }
    if (!formData.productType) {
      formErrors.productType = 'Please select a product type';
    }
    if (!formData.quantity || isNaN(formData.quantity) || formData.quantity <= 0) {
      formErrors.quantity = 'Quantity must be a positive number';
    }
    if (!formData.companyName) {
      formErrors.companyName = 'Company name is required';
    }
    if (!formData.address) {
      formErrors.address = 'Address is required';
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('https://swahilicard.com/api/send-email', formData);
        setFormStatus('Order submitted successfully!');
      } catch (error) {
        console.error('Error submitting order:', error);
        setFormStatus('Error submitting order: ' + (error.response?.data?.message || error.message));
      }
    }
  };

  return (
    <section id="products" className="py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6 gradient-text">Buy Products</h2>
        <p className="text-center text-gray-700 mb-8">
          We have accessories that seamlessly link to your digital business card for easy access.
        </p>

        <div className="max-w-4xl mx-auto p-8 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4"><span className='gradient-text'>Order Now</span> to get started with Swahilicard</h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Work Email*</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="first-name" className="block text-gray-700 font-bold mb-2">First Name*</label>
                <input
                  type="text"
                  id="first-name"
                  name="firstName"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                {errors.firstName && <p className="text-red-500 text-xs mt-2">{errors.firstName}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="last-name" className="block text-gray-700 font-bold mb-2">Last Name*</label>
                <input
                  type="text"
                  id="last-name"
                  name="lastName"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
                {errors.lastName && <p className="text-red-500 text-xs mt-2">{errors.lastName}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">Phone Number*</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                {errors.phone && <p className="text-red-500 text-xs mt-2">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="product-type" className="block text-gray-700 font-bold mb-2">Product Type*</label>
                <select
                  id="product-type"
                  name="productType"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.productType}
                  onChange={handleInputChange}
                >
                  <option value="">Choose</option>
                  <option value="cards">Cards</option>
                  <option value="bands">Bands</option>
                </select>
                {errors.productType && <p className="text-red-500 text-xs mt-2">{errors.productType}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="quantity" className="block text-gray-700 font-bold mb-2">Quantity*</label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.quantity}
                  onChange={handleInputChange}
                />
                {errors.quantity && <p className="text-red-500 text-xs mt-2">{errors.quantity}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="company-name" className="block text-gray-700 font-bold mb-2">Company Name*</label>
                <input
                  type="text"
                  id="company-name"
                  name="companyName"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
                {errors.companyName && <p className="text-red-500 text-xs mt-2">{errors.companyName}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="address" className="block text-gray-700 font-bold mb-2">Address*</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.address}
                  onChange={handleInputChange}
                />
                {errors.address && <p className="text-red-500 text-xs mt-2">{errors.address}</p>}
              </div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <button
                type="submit"
                className="gradient-button text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Submit Order
              </button>
            </div>
            {formStatus && <p className={`text-center mt-4 ${formStatus.startsWith('Error') ? 'text-red-500' : 'text-green-500'}`}>{formStatus}</p>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default BuyProducts;
