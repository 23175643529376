// import React from "react";

// const FaQ = () =>{
//     return(
//         <section id="faq" class="py-12 bg-white">
//     <div class="container mx-auto px-4">
//         <h2 class="text-3xl font-bold text-center mb-6 gradient-text">Frequently Asked Questions</h2>
//         <div class="max-w-4xl mx-auto">
          
//             <div class="border-b border-gray-200 py-4">
//                 <button class="w-full text-left flex items-center justify-between focus:outline-none faq-question">
//                     <span class="text-lg font-medium text-gray-900">What is Swahilicard?</span>
//                     <svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
//                         <path fill-rule="evenodd" d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" clip-rule="evenodd"></path>
//                     </svg>
//                 </button>
//                 <div class="faq-answer mt-4 hidden">
//                     <p class="text-gray-700">
//                         Swahilicard is a digital business card platform that allows you to share up-to-date business information with anyone through a simple scan of a QR code.
//                     </p>
//                 </div>
//             </div>
           
//             <div class="border-b border-gray-200 py-4">
//                 <button class="w-full text-left flex items-center justify-between focus:outline-none faq-question">
//                     <span class="text-lg font-medium text-gray-900">How does Swahilicard work?</span>
//                     <svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
//                         <path fill-rule="evenodd" d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" clip-rule="evenodd"></path>
//                     </svg>
//                 </button>
//                 <div class="faq-answer mt-4 hidden">
//                     <p class="text-gray-700">
//                         Swahilicard works by generating a unique QR code that links to your digital business card. You can share this QR code with others, and they can scan it with their phone to instantly save your contact information.
//                     </p>
//                 </div>
//             </div>
           
//             <div class="border-b border-gray-200 py-4">
//                 <button class="w-full text-left flex items-center justify-between focus:outline-none faq-question">
//                     <span class="text-lg font-medium text-gray-900">Is Swahilicard free?</span>
//                     <svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
//                         <path fill-rule="evenodd" d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" clip-rule="evenodd"></path>
//                     </svg>
//                 </button>
//                 <div class="faq-answer mt-4 hidden">
//                     <p class="text-gray-700">
//                         Swahilicard offers a free basic plan with limited features. There are also premium plans available that provide additional features and customization options.
//                     </p>
//                 </div>
//             </div>
           
//             <div class="border-b border-gray-200 py-4">
//                 <button class="w-full text-left flex items-center justify-between focus:outline-none faq-question">
//                     <span class="text-lg font-medium text-gray-900">How can I customize my Swahilicard?</span>
//                     <svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
//                         <path fill-rule="evenodd" d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" clip-rule="evenodd"></path>
//                     </svg>
//                 </button>
//                 <div class="faq-answer mt-4 hidden">
//                     <p class="text-gray-700">
//                         You can customize your Swahilicard by logging into your account and using the customization tools available. You can change the design, add your logo, update contact information, and more.
//                     </p>
//                 </div>
//             </div>
        
//             <div class="border-b border-gray-200 py-4">
//                 <button class="w-full text-left flex items-center justify-between focus:outline-none faq-question">
//                     <span class="text-lg font-medium text-gray-900">Can I track who scans my Swahilicard?</span>
//                     <svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
//                         <path fill-rule="evenodd" d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" clip-rule="evenodd"></path>
//                     </svg>
//                 </button>
//                 <div class="faq-answer mt-4 hidden">
//                     <p class="text-gray-700">
//                         Yes, Swahilicard provides analytics that allow you to see how many times your card has been scanned and other useful metrics.
//                     </p>
//                 </div>
//             </div>
            
//             <div class="py-4">
//                 <button class="w-full text-left flex items-center justify-between focus:outline-none faq-question">
//                     <span class="text-lg font-medium text-gray-900">How do I contact Swahilicard support?</span>
//                     <svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
//                         <path fill-rule="evenodd" d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" clip-rule="evenodd"></path>
//                     </svg>
//                 </button>
//                 <div class="faq-answer mt-4 hidden">
//                     <p class="text-gray-700">
//                         You can contact Swahilicard support by emailing support@swahilicard.com or by calling +1 (555) 123-4567.
//                     </p>
//                 </div>
//             </div>
//         </div>
//     </div>
// </section>


//     );
// };

// export default FaQ;


import React, { useState } from "react";

const FaQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    const faqItems = [
        {
            question: "What is Swahilicard?",
            answer: "Swahilicard is a digital business card platform that allows you to share up-to-date business information with anyone through a simple scan of a QR code.",
        },
        {
            question: "How does Swahilicard work?",
            answer: "Swahilicard works by generating a unique QR code that links to your digital business card. By tapping on Swahilicard accessories or scanning the QR code, others can instantly save your contact information on their phone.",
        },
        {
            question: "Is Swahilicard free?",
            answer: "Swahilicard offers a free basic plan with limited features. There are also premium plans available that provide additional features and customization options.",
        },
        {
            question: "How can I customize my Swahilicard?",
            answer: "You can customize your Swahilicard by logging into your account and using the customization tools available. You can change the design, add your logo, update contact information, and more.",
        },
        {
            question: "Can I track who scans my Swahilicard?",
            answer: "Yes, Swahilicard provides analytics that allow you to see how many times your card has been scanned and other useful metrics.",
        },
        {
            question: "How do I contact Swahilicard support?",
            answer: "You can contact Swahilicard support by emailing support@swahilicard.com or by calling +255753039599.",
        },
    ];

    return (
        <section id="faq" className="py-12 bg-white">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-6 gradient-text">Frequently Asked Questions</h2>
                <div className="max-w-4xl mx-auto">
                    {faqItems.map((item, index) => (
                        <div key={index} className="border-b border-gray-200 py-4">
                            <button 
                                className="w-full text-left flex items-center justify-between focus:outline-none faq-question"
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className="text-lg font-medium text-gray-900">{item.question}</span>
                                <svg 
                                    className={`w-5 h-5 text-gray-500 transform transition-transform ${openIndex === index ? 'rotate-180' : ''}`} 
                                    viewBox="0 0 20 20" 
                                    fill="currentColor"
                                >
                                    <path 
                                        fillRule="evenodd" 
                                        d="M10 15a1 1 0 01-.707-.293l-5-5a1 1 0 011.414-1.414L10 12.586l4.293-4.293a1 1 0 011.414 1.414l-5 5A1 1 0 0110 15z" 
                                        clipRule="evenodd" 
                                    />
                                </svg>
                            </button>
                            <div className={`faq-answer mt-4 ${openIndex === index ? '' : 'hidden'}`}>
                                <p className="text-gray-700">{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FaQ;
