import React from 'react';

const Sustainability = () => {
  return (
    <section id="sustainability" className="py-16 bg-gradient-to-r">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold gradient-text mb-8">The Swahilicard app is carbon neutral</h2>
        <p className="text-xl text-gray-700 mb-16">Digitalize information sharing with us. Join our effort in reducing carbon footprint.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 bg-gray-50 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">100% carbon neutral.</h3>
            <p className="text-gray-600 mb-4">Our mobile app provides a solution for sharing your information completely digitally and with a zero carbon footprint.</p>
          
          </div>
          <div className="p-6 bg-gray-50 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">100% ESG compliant</h3>
            <p className="text-gray-600 mb-4">By digitizing business cards, we contribute to the development of sustainable business and ensure a higher ESG score for your company.</p>
       
          </div>
          <div className="p-6 bg-gray-50 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">5M trees</h3>
            <p className="text-gray-600 mb-4">Switching to the SwahiliCard app can save up to 5 million trees annually, which are cut down for business card production.</p>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sustainability;
